<template>
    <div class="my_files_widget h-full">
        <vue2Files 
            :isMyFiles="true"
            :isFounder="true"
            :isStudent="true"/>
    </div>
</template>

<script>
import pageMeta from '@/mixins/pageMeta'
export default {
    name: 'PageWidgetsFiles',
    mixins: [pageMeta],
    components: {
        vue2Files: () => import('@apps/vue2Files'),
    },
    metaInfo() {
        return {
            htmlAttrs: {
                class: 'file_page_bg'
            }
        }
    }
}
</script>

<style lang="scss">
.file_page_bg{
    body{
        background: #ffffff!important;
    }
}
</style>

<style lang="scss" scoped>
.my_files_widget {
    padding: 15px;
}
</style>
